/* https://www.cssportal.com/css-ribbon-generator/ */

.box {
  width: 200px;
  height: 300px;
  position: relative;
  border: 1px solid #bbb;
  background: #eee;
}
.ribbon {
  position: absolute;
  right: -5px;
  top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 75px;
  height: 75px;
  text-align: right;
}
.ribbon span {
  font-size: 10px;
  font-weight: bold;
  color: #fff;
  text-transform: capitalize;
  text-align: center;
  line-height: 20px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  width: 100px;
  display: block;
  background: #79a70a;
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 19px;
  right: -21px;
}
.ribbon span::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid #79a70a;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #79a70a;
}
.ribbon span::after {
  content: "";
  position: absolute;
  right: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid transparent;
  border-right: 3px solid #79a70a;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #79a70a;
}

.ribbon.blue span {
  background: #1e5799;
}
.ribbon.blue span::before {
  border-left: 3px solid #1e5799;
  border-top: 3px solid #1e5799;
}
.ribbon.blue span::after {
  border-right: 3px solid #1e5799;
  border-top: 3px solid #1e5799;
}

.ribbon.red span {
  background: #b92230;
}
.ribbon.red span::before {
  border-left: 3px solid #b92230;
  border-top: 3px solid #b92230;
}
.ribbon.red span::after {
  border-right: 3px solid #b92230;
  border-top: 3px solid #b92230;
}
