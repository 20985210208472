.box {
  background: #eee;
  border: 1px solid #bbb;
  width: 200px;
  height: 300px;
  position: relative;
}

.ribbon {
  z-index: 1;
  text-align: right;
  width: 75px;
  height: 75px;
  position: absolute;
  top: -5px;
  right: -5px;
  overflow: hidden;
}

.ribbon span {
  color: #fff;
  text-transform: capitalize;
  text-align: center;
  background: #79a70a;
  width: 100px;
  font-size: 10px;
  font-weight: bold;
  line-height: 20px;
  display: block;
  position: absolute;
  top: 19px;
  right: -21px;
  transform: rotate(45deg);
  box-shadow: 0 3px 10px -5px #000;
}

.ribbon span:before {
  content: "";
  z-index: -1;
  border: 3px solid #0000;
  border-color: #79a70a #0000 #0000 #79a70a;
  position: absolute;
  top: 100%;
  left: 0;
}

.ribbon span:after {
  content: "";
  z-index: -1;
  border: 3px solid #79a70a;
  border-color: #79a70a #79a70a #0000 #0000;
  position: absolute;
  top: 100%;
  right: 0;
}

.ribbon.blue span {
  background: #1e5799;
}

.ribbon.blue span:before {
  border-top: 3px solid #1e5799;
  border-left: 3px solid #1e5799;
}

.ribbon.blue span:after {
  border-top: 3px solid #1e5799;
  border-right: 3px solid #1e5799;
}

.ribbon.red span {
  background: #b92230;
}

.ribbon.red span:before {
  border-top: 3px solid #b92230;
  border-left: 3px solid #b92230;
}

.ribbon.red span:after {
  border-top: 3px solid #b92230;
  border-right: 3px solid #b92230;
}

/*# sourceMappingURL=shop.7ae49fc9.css.map */
